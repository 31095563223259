import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ngx-svg-gauge',
  templateUrl: './svg-gauge.component.html',
  styleUrls: ['./svg-gauge.component.scss']
})
export class SvgGaugeComponent implements OnInit {
  @Input() value = 0;
  strokeDashoffset = 659.4;
  meterRotation = -90;
  pathData = '';

  constructor() {}

  // Convert degrees to radians
  toRadians(angle: number): number {
    return (Math.PI / 180) * angle;
  }

  updateGauge(value: number) {
    const circumference = 659.4;  // Full circumference
    this.strokeDashoffset = value === 100 ? 0 : circumference - (value / 100) * circumference;
    this.meterRotation = -125 + (value / 100) * 252;

    this.pathData = ''; // Reset path data

    if (value === 100) {
      // Full arc path for 100%
      this.pathData = this.generateArc(0, 1);
    } else {
      for (let i = 1; i <= value; i++) {
        this.pathData += this.generateArc((i - 1) / 100, i / 100);
      }
    }
  }

  generateArc(startPercent: number, endPercent: number): string {
    const startAngle = startPercent * 251 - 215;
    const endAngle = endPercent * 251 - 215;

    const startRad = this.toRadians(startAngle);
    const endRad = this.toRadians(endAngle);

    const x1 = 200 + 150 * Math.cos(startRad);
    const y1 = 200 + 150 * Math.sin(startRad);
    const x2 = 200 + 150 * Math.cos(endRad);
    const y2 = 200 + 150 * Math.sin(endRad);

    const largeArcFlag = endAngle - startAngle > 180 ? 1 : 0;

    return `M ${x1},${y1} A 150,150 0 ${largeArcFlag} 1 ${x2},${y2}`;
  }

  ngOnChanges(): void {
    this.updateGauge(this.value);
  }

  ngOnInit(): void {
    this.updateGauge(this.value);
  }
}
