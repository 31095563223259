<div>

<svg width="400" height="400" viewBox="0 0 400 400" xmlns="http://www.w3.org/2000/svg">
    <!-- Outer Gauge Circle Foreground (Semicircle) -->
    <!-- Path that moves around the circumference -->
  <path id="movingPath" fill="none" stroke="#49a010" stroke-width="30" 
  [attr.d] = pathData
  stroke-dasharray="659.4"
  stroke-dashoffset="659.4"
  >
  <animate
  attributeName="stroke-dashoffset"
  from="659.4"
  [attr.to]=strokeDashoffset
  begin="2.8s"    
  dur="3s"
  fill="freeze"
  calcMode="linear"
/>
</path>
    <!-- Outer Gauge Circle Background -->
    <circle
      cx="200"
      cy="200"
      r="150"
      fill="none"
      stroke="#49a010"
      stroke-opacity="0.15"
      stroke-width="30"
      stroke-dasharray="659.4"
      stroke-dashoffset="0"
      transform="rotate(145 200 200)"
    />
  
    <!-- Inner Circle with Gradient -->
    <defs>
      <radialGradient id="innerGradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
        <stop offset="0%" style="stop-color: rgba(255,255,255,1); stop-opacity: 1" />
        <stop offset="35%" style="stop-color: rgba(221,66,66,1); stop-opacity: 1" />
      </radialGradient>
    </defs>
    <circle
      cx="200"
      cy="200"
      r="140"
      fill="url(#innerGradient)"
      stroke="#FFFF99"
      stroke-width="10"
    />
  
    <!-- Meter Hand -->
    <polygon
      points="200,30 190,200 210,200 200,30"
      fill="#fff"
      
    >
          <!-- Rotation Animation -->
          <animateTransform
          attributeName="transform"
          type="rotate"
          from="-125 200 200" 
          [attr.to]= " meterRotation + ' 200 200'"   
          dur="3s"          
          fill="freeze"   
        />
    </polygon>
    <!-- Center Circle for Aesthetic -->
    <circle
      cx="200"
      cy="200"
      r="10"
      fill="#fff"
    />


      <!-- Scale Ticks and Labels -->
  <g transform="rotate(-124.5 200 200)">
    <!-- Ticks and Labels -->
    <g>
      <line x1="200" y1="35" x2="200" y2="40" stroke="#000" stroke-width="2" />
      <text x="200" y="25" text-anchor="middle" font-size="12" fill="#000">0</text>
    </g>
    <g transform="rotate(25, 200, 200)">
      <line x1="200" y1="35" x2="200" y2="40" stroke="#000" stroke-width="2" />
      <text x="200" y="25" text-anchor="middle" font-size="12" fill="#000">10</text>
    </g>
    <g transform="rotate(50, 200, 200)">
      <line x1="200" y1="35" x2="200" y2="40" stroke="#000" stroke-width="2" />
      <text x="200" y="25" text-anchor="middle" font-size="12" fill="#000">20</text>
    </g>
    <g transform="rotate(75, 200, 200)">
      <line x1="200" y1="35" x2="200" y2="40" stroke="#000" stroke-width="2" />
      <text x="200" y="25" text-anchor="middle" font-size="12" fill="#000">30</text>
    </g>
    <g transform="rotate(100, 200, 200)">
      <line x1="200" y1="35" x2="200" y2="40" stroke="#000" stroke-width="2" />
      <text x="200" y="25" text-anchor="middle" font-size="12" fill="#000">40</text>
    </g>
    <g transform="rotate(125, 200, 200)">
      <line x1="200" y1="35" x2="200" y2="40" stroke="#000" stroke-width="2" />
      <text x="200" y="25" text-anchor="middle" font-size="12" fill="#000">50</text>
    </g>
    <g transform="rotate(150, 200, 200)">
      <line x1="200" y1="35" x2="200" y2="40" stroke="#000" stroke-width="2" />
      <text x="200" y="25" text-anchor="middle" font-size="12" fill="#000">60</text>
    </g>
    <g transform="rotate(175, 200, 200)">
      <line x1="200" y1="35" x2="200" y2="40" stroke="#000" stroke-width="2" />
      <text x="200" y="25" text-anchor="middle" font-size="12" fill="#000">70</text>
    </g>
    <g transform="rotate(200, 200, 200)">
      <line x1="200" y1="35" x2="200" y2="40" stroke="#000" stroke-width="2" />
      <text x="200" y="25" text-anchor="middle" font-size="12" fill="#000">80</text>
    </g>
    <g transform="rotate(225, 200, 200)">
      <line x1="200" y1="35" x2="200" y2="40" stroke="#000" stroke-width="2" />
      <text x="200" y="25" text-anchor="middle" font-size="12" fill="#000">90</text>
    </g>
    <g transform="rotate(250, 200, 200)">
      <line x1="200" y1="35" x2="200" y2="40" stroke="#000" stroke-width="2" />
      <text x="200" y="25" text-anchor="middle" font-size="12" fill="#000">100</text>
    </g>
  </g>
</svg>
</div>